import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2024.03.04',
    text: '「Money Farm」を提供する株式会社3rd Economyがシードラウンドにて4,000万円を資金調達',
    url: 'https://prtimes.jp/main/html/rd/p/000000003.000134750.html'
  },
  {
    date: '2023.09.19',
    text: 'Relicホールディングス、国内初のトークン化債権のマーケットプレイスの提供を目指す「株式会社3rd Economy」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000200.000016318.html'
  }
  // {date: "2099.09.28", text: "テスト", url: "https://prtimes.jp/main/html/rd/p/000000085.000016318.html"}
]
