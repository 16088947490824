import React from 'react'
import { css, SerializedStyles } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'

import arrow_white from '@/images/arrow_white.svg'
import { cssThirdrdeconomyButton2 } from '@/components/3rdeconomy/linkStyles'
import { StyledLink } from '@/components/link'

export type ServiceType = {
  description: string
  image: { url: string }
  logo: {
    url: string
    pcWidth: number
    spWidth: number
  }
  link: {
    url: string
    label: string
  }
}

type Props = {
  service: ServiceType
  theme?: SerializedStyles
}

export const ServiceCard = (props: Props): JSX.Element => {
  const { service, theme } = props

  const cssLogo = css`
    margin-bottom: 19px;
    max-width: ${service.logo.spWidth}px;

    ${mq.pc} {
      justify-content: flex-start;
      max-width: ${service.logo.pcWidth}px;
      margin-bottom: 44px;
    }
  `

  return (
    <div css={[cssCard, theme]}>
      <div css={cssDescriptionLayout}>
        <img css={cssLogo} src={service.logo.url} alt="" />
        <p css={cssParagraph}>{service.description}</p>
        {/* Gatsby Linkでもtarget="_blank"できる噂ありだけど現状失敗。gatsbyのversion依存? */}
        <StyledLink
          href={service.link.url}
          theme={cssThirdrdeconomyButton2}
          src={arrow_white}
        >
          {service.link.label}
        </StyledLink>
      </div>
      <div css={cssImageLayout}>
        <img src={service.image.url} alt="" />
      </div>
    </div>
  )
}

const cssCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
  padding: 34px 28px;
  margin: 0 auto;
  background-color: ${color.white1};
  box-shadow: 0 3px 30px ${color.black3};

  ${mq.mid} {
    flex-direction: row;
    justify-content: space-around;
    padding: 105px 35px 92px 76px;
    max-width: 1200px;
    border-bottom-right-radius: 24px;
  }
`

const cssDescriptionLayout = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${mq.mid} {
    align-items: flex-start;
    max-width: 431px;
  }
`

const cssImageLayout = css`
  order: -1;
  width: 100%;

  img {
    box-shadow: 0px 0px 15px 0px #0000001a;
  }

  ${mq.mid} {
    order: 1;
    max-width: 550px;
  }
`

const cssParagraph = css`
  margin-bottom: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  color: ${color.black1};
  white-space: pre-wrap;
  letter-spacing: 0.07em;

  ${mq.pc} {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
  }
`